html {
  width: 100vw;
  height: 99vh; }

body {
  width: 100vw;
  height: calc(100vh - 64px);
  margin: 0;
  padding: 0;
  background-color: #f0f2f5 !important;
  overflow-y: scroll;
  overflow-x: hidden; }

#root {
  width: 100vw;
  height: calc(100vh - 64px); }

.cursor-pointer {
  cursor: pointer; }

div::-webkit-scrollbar {
  display: none; }

.shadow-pin {
  width: 100px !important; }

.esri-popup__main-container {
  max-height: inherit !important; }

.esri-popup {
  z-index: 3 !important; }

.notification-dropdown > * {
  white-space: normal !important;
  width: 300px; }

.card {
  font-size: 1.2em; }

.s-alert-wrapper,
.s-alert-box {
  z-index: 2000; }

.user-dropdown .text:first-child {
  display: none !important; }

.wrapped-tab-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.DraftEditor-editorContainer {
  min-height: 150px; }

/*==================================
    TIMELINE
==================================*/
/*-- GENERAL STYLES
    ------------------------------*/
.timeline {
  line-height: 1.4em;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%; }
  .timeline h1, .timeline h2, .timeline h3, .timeline h4, .timeline h5, .timeline h6 {
    line-height: inherit; }

/*----- TIMELINE ITEM -----*/
.timeline-item {
  padding-left: 40px;
  position: relative; }
  .timeline-item:last-child {
    padding-bottom: 0; }
  .timeline-item .timeline-marker:before {
    background: #2185D0; }
  .timeline-item--disabled {
    opacity: 0.3; }

/*----- TIMELINE INFO -----*/
.timeline-info {
  font-size: 1.5em;
  font-weight: 700;
  letter-spacing: 3px;
  margin: 0 0 .5em 0;
  text-transform: uppercase;
  white-space: nowrap;
  color: #2185D0; }

/*----- TIMELINE MARKER -----*/
.timeline-marker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15px; }
  .timeline-marker:before {
    background: #3FA2F7;
    border: 3px solid transparent;
    border-radius: 100%;
    content: "";
    display: block;
    height: 15px;
    position: absolute;
    top: 4px;
    left: 0;
    width: 15px;
    transition: background 0.3s ease-in-out, border 0.3s ease-in-out; }
  .timeline-marker:after {
    content: "";
    width: 3px;
    background: #CCD5DB;
    display: block;
    position: absolute;
    top: 24px;
    bottom: 0;
    left: 6px; }
  .timeline-item:last-child .timeline-marker:after {
    content: none; }

/*----- TIMELINE CONTENT -----*/
.timeline-content {
  padding-bottom: 40px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important; }
  .timeline-content .timeline-description {
    text-align: center; }

/*----- TIMELINE PERIOD -----*/
.period {
  padding: 0; }
  .period .timeline-info {
    display: none; }
  .period .timeline-marker:before {
    background: transparent;
    content: "";
    width: 15px;
    height: auto;
    border: none;
    border-radius: 0;
    top: 0;
    bottom: 30px;
    position: absolute;
    border-top: 3px solid #CCD5DB;
    border-bottom: 3px solid #CCD5DB; }
  .period .timeline-marker:after {
    content: "";
    height: 32px;
    top: auto; }
  .period .timeline-content {
    padding: 40px 0 70px; }
  .period .timeline-title {
    margin: 0; }

/*----------------------------------------------
      MOD: TIMELINE SPLIT
  ----------------------------------------------*/
@media (min-width: 768px) {
  .timeline-split .timeline, .timeline-centered .timeline {
    display: table; }
  .timeline-split .timeline-item, .timeline-centered .timeline-item {
    display: table-row;
    padding: 0; }
  .timeline-split .timeline-info, .timeline-centered .timeline-info,
  .timeline-split .timeline-marker,
  .timeline-centered .timeline-marker,
  .timeline-split .timeline-content,
  .timeline-centered .timeline-content,
  .timeline-split .period .timeline-info,
  .timeline-centered .period .timeline-info {
    display: table-cell;
    vertical-align: top; }
  
  .timeline-split .timeline-marker,
  .timeline-centered .timeline-marker {
    position: relative; }
  
  .timeline-split .timeline-content,
  .timeline-centered .timeline-content {
    padding-left: 30px; }
  .timeline-split .timeline-info, .timeline-centered .timeline-info {
    padding-right: 30px; }
  .timeline-split .period .timeline-title, .timeline-centered .period .timeline-title {
    position: relative;
    left: -45px; } }

/*----------------------------------------------
      MOD: TIMELINE CENTERED
  ----------------------------------------------*/
@media (min-width: 992px) {
  .timeline-centered,
  .timeline-centered .timeline-item,
  .timeline-centered .timeline-info,
  .timeline-centered .timeline-marker,
  .timeline-centered .timeline-content {
    display: block;
    margin: 0;
    padding: 0; }
  .timeline-centered .timeline-item {
    padding-bottom: 40px;
    overflow: hidden; }
  .timeline-centered .timeline-marker {
    position: absolute;
    left: 50%;
    margin-left: -7.5px; }
  .timeline-centered .timeline-info,
  .timeline-centered .timeline-content {
    width: 50%; }
  .timeline-centered > .timeline-item:nth-child(odd) .timeline-info {
    float: left;
    text-align: right;
    padding-right: 30px; }
  .timeline-centered > .timeline-item:nth-child(odd) .timeline-content {
    float: right;
    text-align: left;
    padding-left: 30px; }
  .timeline-centered > .timeline-item:nth-child(even) .timeline-info {
    float: right;
    text-align: left;
    padding-left: 30px; }
  .timeline-centered > .timeline-item:nth-child(even) .timeline-content {
    float: left;
    text-align: right;
    padding-right: 30px; }
  .timeline-centered > .timeline-item.period .timeline-content {
    float: none;
    padding: 0;
    width: 100%;
    text-align: center; }
  .timeline-centered .timeline-item.period {
    padding: 50px 0 90px; }
  .timeline-centered .period .timeline-marker:after {
    height: 30px;
    bottom: 0;
    top: auto; }
  .timeline-centered .period .timeline-title {
    left: auto; } }

/*----------------------------------------------
      MOD: MARKER OUTLINE
  ----------------------------------------------*/
.marker-outline .timeline-marker:before {
  background: transparent;
  border-color: #3FA2F7; }

.marker-outline .timeline-item:hover .timeline-marker:before {
  background: #3FA2F7; }
